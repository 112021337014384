<template>
  <div>
    <AutoPopup />
    <div class="a-profile-subpage-title -certificates">
      <div>
        <h1 class="w-color-orange-diploms">Diplomy</h1>
        <p class="w-color-black">Zoznam diplomov</p>
      </div>
      <div class="z-icon-wrapper -large">
        <svg-ribbon-icon class="w-color-orange-diploms -ribbon" />
      </div>
    </div>
    <b-tabs>
      <b-tab title="Zoznam diplomov" active>
        <CertificateTable
          v-if="showPathCertificates"
          @itemToDownload="downloadItem"
          :tableFields="[pathHeading, ...commonFields]"
          :tableItems="pathCertificates"
          class="mt-5"
        />

        <div v-else class="d-flex justify-content-center m-5">
          <div class="m-5 text-center">
            <h5>
              {{
                isAdmin
                  ? "Keď úspešne zvládnete ľubovoľnú Sériu, môžete získať diplom"
                  : "Keď úspešne zvládneš ľubovoľnú Sériu, môžeš získať diplom"
              }}
            </h5>
            <b-button
              class="mt-3"
              type="button"
              pill
              size="sm"
              variant="primary mb-0"
              @click="$router.push('/kurzy')"
            >
              Pozrieť Série
            </b-button>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { sortBy } from "lodash";
import { mapGetters } from "vuex";
import { downloadCertificate } from "/utils/downloadCertificate";
import AutoPopup from "/components/AutoPopup.vue";
import * as Sentry from "@sentry/vue";

export default {
  layout: "profile",

  components: {
    AutoPopup,
    CertificateTable: () => import("/components/CertificateTable.vue"),
    "svg-ribbon-icon": () => import("/assets/icons/ribbon.svg?inline"),
  },

  data() {
    return {
      commonFields: [
        { key: "created_at", label: "Dátum získania", class: "created-at" },
        { key: "download", label: "", class: "download" },
      ],
      courseHeading: {
        key: "name",
        label: "Absolvované kurzy",
        class: "name",
      },
      pathHeading: {
        key: "name",
        label: "Absolvované série",
        class: "name",
      },
      storeUserInfo: null,
      pathCertificates: null,
      rawPathCertificates: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
    isAdmin() {
      return this.user && this.user.type === "teacher";
    },
    showPathCertificates() {
      return this.pathCertificates && this.pathCertificates.length;
    },
  },

  async created() {
    try {
      this.storeUserInfo = await this.$store.dispatch("auth/user");
      this.rawPathCertificates = this.storeUserInfo.pathCertificates;
    } catch (error) {
      Sentry.captureException(error);
      this.userInfo = { error: "Nepodarilo sa načítať Váš profil..." };
    }
    await this._loadCertificates();
  },

  methods: {
    async _loadCertificates() {
      this.pathCertificates = this.rawPathCertificates.map((certificate) => {
        return {
          createdAt: certificate.createdAt,
          path: {
            image: {
              path: certificate.path.thumbnail.url,
            },
            name: certificate.path.name,
            id: certificate.path.id,
          },
          user: {
            name: this.storeUserInfo.firstName,
            surname: this.storeUserInfo.lastName,
          },
        };
      });
      this.pathCertificates = sortBy(this.pathCertificates, (e) => e.createdAt);
    },
    async downloadItem(certificate) {
      this.$eventLogger.userLog("certificate.download", {
        path_id: certificate.path.id,
      });

      downloadCertificate(certificate);
    },
  },
};
</script>
